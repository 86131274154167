<template>
  <div class="coming-box">
    <img src="../assets/images/empty/coming.svg" alt="">
    <span>Coming soon</span>
  </div>
</template>

<script setup>

</script>

<style lang="less" scoped>
.coming-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 200px;

  span {
    padding-top: 24px;
    font-weight: 400;
    font-size: 14px;
  }
}
</style>
